<template>
  <b-card>
    <b-modal
      ref="search-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="selectedItem.id > 0 ? $t('edit') : $t('new')"
    >
      <b-row
        v-if="selectedItem.storeId"
      >
        <b-col md="4">
          <g-field
            id="filterCode"
            :value.sync="filter.barcode"
            label-text="barcode"
            name="barcode"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
            @change="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="arabicName"
            v-manage-value="id ? '' : $refs.englishName"
            :value.sync="filter.arabicNamePart"
            label-text="arabicName"
            name="arabicName"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
            @change="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="filter.englishNamePart"
            label-text="englishName"
            name="arabicName"
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
            @change="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col
          cols="12"
          class="medium-window"
        >
          <b-table
            ref="itemstable"
            :items="itemsSearchProvider"
            :fields="tableColumns"
            :is-busy="isTableBusy"
            primary-key="id"
            show-empty
            striped
            hover
            small
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :sort-direction="sortDirection"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            :empty-text="$t('noMatchingRecordsFound')"
            :tbody-tr-class="(item) => {
              if (item && item.components.length > 0 && currentBranch.activityType === 'restaurant') return 'table-danger';
            }"
          >
            <template #head(actions)>
              <span />
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="{ item }">
              <b-button
                size="sm"
                variant="outline-primary"
                :disabled="item.components.length > 0 && currentBranch.activityType === 'restaurant'"
                @click="
                  (val) => {
                    addItemToItemsTable(item);
                  }
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
          <b-row class="pt-1">
            <!-- Pagination -->
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                :label="$t('entries')"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="perPageOptions"
                />
              </b-form-group>
              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end py-1 pb-2"
        >
          <b-button
            class="mx-1"
            variant="secondary"
            @click="closeModal"
          >
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-content-between mb-1">
      <!-- <div class="col-md-4 col-12">
        </div> -->
      <div
        v-if="!selectedItem.isComplete"
        class="d-flex align-items-center justify-content-start mb-1 col-md-12 col-12"
      >
        <b-button
          variant="gradient-primary"
          data-action-type="new"
          class="mr-1"
          @click="
            (v) => {
              openExcelModal();
            }
          "
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          {{ $t('uploadItemsFromExcelFile') }}
        </b-button>
        <b-button
          variant="outline-primary"
          data-action-type="new"
          @click="
            (v) => {
              downloadExcelSheet();
            }
          "
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          {{ $t('downloadExcelSheet') }}
        </b-button>
      </div>
    </div>
    <g-form @submit="save">
      <b-row>
        <b-col md="3">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            disabled
          />
        </b-col>
        <!-- date  -->
        <b-col md="3">
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="transactionDate"
            name="date"
          />
        </b-col>
        <b-col md="3">
          <g-field
            :value.sync="selectedItem.transactionTime"
            label-text="transactionTime"
            name="transactionTime"
            readonly
          />
        </b-col>
        <b-col md="3">
          <g-field
            :options="stores"
            label-text="store"
            field="select"
            rules="required"
            name="StoreId"
            :value.sync="selectedItem.storeId"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :disabled="selectedItem.isComplete || selectedItem.lines.length > 0"
            @change="(v) => {
              filter.storeId = v.id;
            }"
          />
        </b-col>
        <b-col
          v-if="id"
          md="3"
        >
          <g-field
            label-text="createdBy"
            :value="selectedItem.createdUserName"
            placeholder="createdBy"
            name="createdBy"
            readonly
          />
        </b-col>
        <b-col
          v-if="id && selectedItem.updatedUserName"
          md="3"
        >
          <g-field
            label-text="updatedBy"
            :value="selectedItem.updatedUserName"
            placeholder="updatedBy"
            name="updatedBy"
            readonly
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!selectedItem.isComplete"
        class="justify-content-between"
      >
        <b-col
          cols="12"
          md="3"
          class="align-items-center justify-content-start"
        >
          <g-field
            label-text="category"
            :value.sync="categoryId"
            :dir="isRight ? 'rtl' : 'ltr'"
            field="select"
            :options="categories"
            :label="isRight ? 'arabicName' : 'englishName'"
            @change="(v) => {
              addAllCatItems();
            }"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="align-items-center justify-content-end">
            <label
              style="font-size: 14px;margin-bottom: 7px;"
              for="purchase"
            >
              {{ $t('barcode') }}
            </label>
            <b-form-input
              v-model="barcode"
              class="d-inline-block mr-1"
              :clearable="true"
              :placeholder="$t('search')"
              @keydown.enter.prevent="getItemWithBarcode"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-end m-auto pt-1"
        >
          <b-button
            variant="gradient-primary"
            @click="openModal"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            {{ $t('addItems') }}
          </b-button>
          <b-button
            class="ml-50"
            variant="outline-primary"
            @click="addAllItems"
          >
            <feather-icon
              icon="ListIcon"
              class="mr-50"
            />
            {{ $t('addAllItems') }}
          </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <!-- second table -->
        <b-col>
          <b-table
            ref="items-table"
            primary-key="id"
            show-empty
            striped
            hover
            response
            small
            sticky-column
            :items="selectedItem.lines"
            :fields="secondTableColumns"
            :per-page="selectedItem.lines.length"
            :is-busy="isTableBusy"
            :no-action="true"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            :empty-text="$t('noMatchingRecordsFound')"
          >
            <template #cell(actualQuantity)="{ item }">
              <g-field
                :value.sync="item.actualQuantity"
                rules="required"
                :short-desc="true"
                type="number"
                size="sm"
                class="mb-0 d-inline"
                :style="{ width: '100px' }"
              />
            </template>
            <template #head(actions)>
              <span />
            </template>
            <!-- Column: Actions -->
            <template
              v-if="!selectedItem.isComplete"
              #cell(actions)="{ item }"
            >
              <div class="text-nowrap">
                <b-button
                  v-b-tooltip.hover.top="$t('delete')"
                  variant="flat-danger"
                  size="sm"
                  @click="removeItemFromTable(item)"
                >
                  <feather-icon
                    :id="`invoice-row-${item.id}-delete-icon`"
                    icon="TrashIcon"
                    stroke="red"
                    class="mx-0 clickable danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          md="3"
          xs="12"
        >
          <b-button
            v-if="!selectedItem.isComplete"
            v-permission="$route.meta.permission"
            variant="gradient-danger"
            data-action-type="save"
            :disabled="!id"
            @click="closeInventory"
          >
            <feather-icon
              icon="XOctagonIcon"
              class="mr-50"
            />
            {{ $t('closeInventory') }}
          </b-button>
        </b-col>
        <b-col
          md="9"
          xs="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-if="id || currentBranch.isMaster"
            class="ml-50"
            variant="outline-primary"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('print') }}
          </b-button>
          <b-button
            v-if="!selectedItem.isComplete"
            v-permission="'closeStocktaking'"
            class="ml-50"
            type="submit"
            data-action-type="save"
            variant="relief-primary"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t('save') }}
          </b-button>
          <b-button
            v-if="!selectedItem.isComplete"
            v-permission="'closeStocktaking'"
            class="ml-50"
            data-action-type="save"
            variant="outline-primary"
            @click="printItemsForStocktaking()"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('saveAndPrintItemForStocktaking') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('importNewItems')"
    >
      <g-form @submit="uploadItems">
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                accept=".xlsx, .xls"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              type="submit"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="secondary"
              @click="closeExcelModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: { FeatherIcon },
  props: ['id'],
  mixins: [reportMixin],
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      sortBy: 'id',
      isSortDirDesc: true,
      sortDirection: 'asc',
      selectedItem: {
        isOffline: false,
        transactionSource: 'web',
        transactionType: 'stocktaking',
        payemntType: '',
        transactionDate: this.today,
        storeId: null,
        lines: [],
        taxes: [],
        payments: [],
      },
      filter: {
        code: '',
        arabicNamePart: '',
        englishNamePart: '',
        OrderClause: '',
        storeId: '',
      },
      perPage: 25,
      isTableBusy: false,
      isDisabled: true,
      filterOn: [],
      PriceListItem: [],
      units: [],
      commingItems: [],
      searchQuery: '',
      defaultSelected: {},
      stores: [],
      categoryId: null,
      barcode: null,
      categories: [],
      fileFromBE: '',
      file: '',
      selectedPrintedItem: {
        id: null,
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'mainUnitBarcode', label: this.$t('barcode'), sortable: true },
        // { key: 'mainUnitBarcode', label: this.$t('barcode'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
          sortable: false,
          type: 'number'
        },
        { key: 'actions' },
      ];
    },
    secondTableColumns() {
      return [
        { key: 'barcode', label: this.$t('barcode'), sortable: false },
        {
          key: this.isRight ? 'itemArabicName' : 'itemEnglishName',
          label: this.$t('name'),
          sortable: false,
        },
        {
          key: 'quantity',
          label: this.$t('creditBalance'),
          sortable: false,
          type: 'number'
        },
        {
          key: 'actualQuantity',
          label: this.$t('actualBalance'),
          sortable: false,
          type: 'number'
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.selectedItem.transactionTime = this.time;
    this.selectedItem.transactionDate = this.today;
    this.selectedItem.storeId = this.currentBranch.defaultStoreId || null;
    this.getStores();
    this.loadData();
    if (this.id > 0) this.getData();
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.isExcel(this.file)) {
        this.file = '';
        this.doneAlert({ text: this.$t('Only supports upload .xlsx, .xls, .csv suffix files'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    uploadItems() {
    const formData = new FormData();
    formData.append('file', this.file);
    this.create({ url: `ItemTransactions/data-migration/${this.selectedItem.storeId}`, data: formData })
      .then((data) => {
        data.forEach((item) => {
            const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
            item.quantity = store.currentBalance;
            item.actualQuantity = store.actualBalance;
            item.unitId = item.mainUnitId;
            item.subUnits.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
            });
            this.addItemToItemsTable(item);
          });
        this.closeExcelModal();
        this.doneAlert({ text: this.$t('savedSuccessfully') });
      });
    },
    closeExcelModal() {
      this.$refs['edit-modal'].hide();
      this.file = '';
    },
    openExcelModal() {
      this.$refs['edit-modal'].show();
    },
    downloadExcelSheet() {
      this.get({ url: 'ItemTransactions/data-migration', responseType: 'arraybuffer' }).then((data) => {
        saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'itemData.xlsx');
      });
    },
    loadData() {
      this.get({ url: 'Aggregates/Items' }).then((data) => {
        this.categories = data.itemCategories;
      });
    },
    getStores() {
      this.get({ url: 'Stores' }).then((data) => {
        this.stores = data;
      });
    },
    getData() {
      if (this.id) {
        this.get({
          url: 'itemTransactions/stocktaking',
          id: this.id,
        }).then((data) => {
          this.selectedItem = data;
        });
      }
    },
    refreshTable() {
      // this.$refs['items-table'].refresh();
      this.$refs.itemstable.refresh();
    },
    refreshItems() {
      this.refreshTable();
    },
    addAllItems() {
          if (!this.selectedItem.storeId) {
        this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
         return
      }
        this.selectedItem.lines = [];
        this.categoryId = null;
        this.get({ url: `items?storeId=${this.selectedItem.storeId}&PageSize=500` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.filter((item) => {
            item.quantity = 0;
            return false;
          });
          data.forEach((item) => {
            const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
            item.quantity = store.currentBalance;
            item.unitId = item.mainUnitId;
            item.price = store.costPrice;
            item.costPrice = store.costPrice;
            item.total = item.price * item.quantity;
            item.net = item.total;
            item.subUnits.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
            });
            this.addItemToItemsTable(item);
          });
        })
        .catch(() => {
          this.isTableBusy = false;
        });
      return null;
    },
    addAllCatItems() {
          if (!this.selectedItem.storeId) {
          this.categoryId = null;
        this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
         return
      }
      this.selectedItem.lines = [];
        this.get({ url: `items?storeId=${this.selectedItem.storeId}&categoryId=${this.categoryId}&PageSize=500` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.filter((item) => {
            item.quantity = 0;
            return false;
          });
          data.forEach((item) => {
            const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
            item.quantity = store.currentBalance;
            item.unitId = item.mainUnitId;
            item.price = store.costPrice;
            item.costPrice = store.costPrice;
            item.total = item.price * item.quantity;
            item.net = item.total;
            item.subUnits.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
            });
            this.addItemToItemsTable(item);
          });
        })
        .catch(() => {
          this.isTableBusy = false;
        });
      return null;
    },
    addItemToItemsTable(item) {
      if (
        this.selectedItem.lines.find(
          (o1) => o1.itemId === item.id && o1.unitId === item.unitId
        )
      ) {
        this.doneAlert({ text: this.$t('canNotRepeatTheItem'), type: 'error' });
      } else {
        const units = [...item.subUnits];
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName || '',
          unitEnglishName: item.mainUnitEnglishName || '',
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
        item.price = store.costPrice;
            item.costPrice = store.costPrice;
            // item.total = store.costPrice * Number(item.actualQuantity);
        this.selectedItem.lines.push({
          ...item,
          lineSerial: this.selectedItem.lines.length + 1,
          storeId: this.selectedItem.storeId,
          itemId: item.id,
          itemCode: item.code,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity || 0,
          price: store.costPrice,
          costPrice: store.costPrice,
          // total: store.costPrice * Number(item.actualQuantity),
          // net: store.costPrice * Number(item.actualQuantity),
          itemArabicName: item.arabicName,
          itemEnglishName: item.englishName,
          barcode: item.mainUnitBarcode,
          unitId: item.unitId, // item.mainUnitId,
          unitArabicName: item.subUnits.length ? item.subUnits.filter((v) => v.unitId === item.unitId)[0].unitArabicName : '', // item.defaultSelected.unitArabicName, // item.mainUnitArabicName,
          unitEnglishName: item.subUnits.length ? item.subUnits?.filter((v) => v.unitId === item.unitId)[0].unitEnglishName : '', // item.defaultSelected.unitEnglishName, // item.mainUnitEnglishName,
         isOld: false,
          units,
        });
      }
    },
    getItemWithBarcode() {
      if (!this.selectedItem.storeId || !this.barcode) return false;
      this.get({ url: `items?barcode=${this.barcode}&WholeWord=true&storeId=${this.selectedItem.storeId}&pageSize=1` }).then(({ data }) => {
        const item = data[0];
        if (!item || !item.id) return;
        if (data && data.length > 0) {
          const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
          item.quantity = store.currentBalance;
            item.unitId = item.mainUnitId;
            item.subUnits.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
            });
          this.addItemToItemsTable(item);
        }
        this.barcode = '';
      });
      return false;
    },
    removeItemFromTable(addedItem) {
      this.selectedItem.lines = this.selectedItem.lines.filter(
        (item) => item !== addedItem
      );
    },
    save() {
      this.selectedItem.lines.forEach(element => {
        element.net = element.costPrice * Number(element.actualQuantity);
        element.total = element.costPrice * Number(element.actualQuantity);
      });
      this.selectedItem.netBeforeTaxes = this.selectedItem.lines.reduce((sum, item) => {
        sum += item.costPrice * Number(item.actualQuantity);
        return sum;
      }, 0);
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.selectedItem.total = this.selectedItem.netBeforeTaxes;
      if (this.selectedItem.id) {
        this.update({
          url: 'itemTransactions/stocktaking',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'inventory' });
          });
      } else {
        this.create({
          url: 'itemTransactions/stocktaking',
          data: this.selectedItem,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.$router.push({ name: 'inventory' });
          });
      }
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `items${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.filter((item) => {
            item.quantity = 0;
            return false;
          });
          data.forEach((item) => {
            const store = item.stores.find((val) => val.storeId === this.selectedItem.storeId) || { currentBalance: 0, costprice: 0 };
            item.quantity = store.currentBalance;
            item.unitId = item.mainUnitId;
            item.subUnits.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
            });
          });
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    closeInventory() {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToClose'),
        },
        () => {
          this.create({ url: `ItemTransactions/Stocktaking/${this.id}/actions/close` }).then(() => {
          this.doneAlert({ text: this.$t('closedSuccessfully') });
          this.$router.push({ name: 'inventory' });
        });
        }
      );
    },
    openModal() {
       if (!this.selectedItem.storeId) {
        this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
         return
      }
      this.$refs['search-modal'].show();
    },
    closeModal() {
      this.$refs['search-modal'].hide();
      this.filter = {
        barcode: '',
        arabicNamePart: '',
        englishNamePart: '',
        OrderClause: '',
      }
    },
    print(id) {
      const printedItem = {
        id: id
      }
       this.printReport('Stocktaking-ar', printedItem);
    },
    printItemsForStocktaking() {
      this.selectedItem.lines.forEach(element => {
        element.net = element.costPrice * Number(element.actualQuantity);
        element.total = element.costPrice * Number(element.actualQuantity);
      });
      this.selectedItem.netBeforeTaxes = this.selectedItem.lines.reduce((sum, item) => {
        sum += item.costPrice * Number(item.actualQuantity);
        return sum;
      }, 0);
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.selectedItem.total = this.selectedItem.netBeforeTaxes;
      if (this.selectedItem.id) {
        this.update({
          url: 'itemTransactions/stocktaking',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'inventory' });
          });
          this.selectedPrintedItem.id = this.selectedItem.id;
          this.printReport('PrintItemsForStocktaking-ar',this.selectedPrintedItem);
      } else {
        this.create({
          url: 'itemTransactions/stocktaking',
          data: this.selectedItem,
        })
          .then((dataId) => {
            this.selectedPrintedItem.id = dataId;
            this.printReport('PrintItemsForStocktaking-ar',this.selectedPrintedItem);
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.$router.push({ name: 'inventory' });
          });
      }
    }
  },
};
</script>
